import React, { useState } from "react";

import { Button } from "@/components/ui/button";
import Select from "@/components/select";
import i18n from "@/i18n";
import {
	ChevronFirst,
	ChevronLast,
	ChevronLeft,
	ChevronRight,
} from "lucide-react";
import { cn } from "@/components/utils";

const { t } = i18n;

const MIN_PAGE_SIZE = 15;

const usePagination = (
	allData = [],
	{
		initialPageSize = MIN_PAGE_SIZE,
		initialCurrentPageIndex = 0,
		disablePageSizeSelector,
	} = {},
) => {
	const [currentPageIndex, setCurrentPageIndex] = useState(
		initialCurrentPageIndex,
	);

	const [pageSize, setPageSize] = useState(initialPageSize);

	const totalPages = Math.ceil(allData.length / pageSize);

	const startIndex = currentPageIndex * pageSize;
	const endIndex =
		startIndex + pageSize > allData.length
			? allData.length
			: startIndex + pageSize;

	const canGoPrev = currentPageIndex > 0;
	const canGoNext = currentPageIndex < totalPages - 1;

	const pageData = allData.slice(startIndex, endIndex);

	const paginationElement =
		allData.length < pageSize ? null : (
			<div className="flex justify-center items-center p-6">
				<div
					className={cn("grid w-full", {
						"grid-cols-2": disablePageSizeSelector,
						"grid-cols-3": !disablePageSizeSelector,
					})}
				>
					{disablePageSizeSelector ? null : (
						<div className="flex items-center justify-start gap-2">
							<Select
								className="rounded-lg"
								options={[
									{ label: String(MIN_PAGE_SIZE), value: MIN_PAGE_SIZE },
									{ label: "35", value: 35 },
									{ label: "75", value: 75 },
								]}
								value={pageSize}
								onChange={(size) => {
									setPageSize(size);
									setCurrentPageIndex(0);
								}}
							/>
							{t("pagination.rows_per_page")}
						</div>
					)}
					<div className="flex align-center justify-center gap-2">
						<Button
							className="rounded-full"
							variant="outline"
							disabled={!canGoPrev}
							onClick={() => setCurrentPageIndex(0)}
						>
							<ChevronFirst size={14} />
						</Button>
						<Button
							className="rounded-full"
							variant="outline"
							disabled={!canGoPrev}
							onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
						>
							<ChevronLeft size={14} />
						</Button>
						<Button
							className="rounded-full"
							variant="outline"
							disabled={!canGoNext}
							onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
						>
							<ChevronRight size={14} />
						</Button>
						<Button
							className="rounded-full"
							variant="outline"
							disabled={!canGoNext}
							onClick={() => setCurrentPageIndex(totalPages - 1)}
						>
							<ChevronLast size={14} />
						</Button>
					</div>
					<div className="flex items-center justify-end gap-2">
						<strong>
							{startIndex}-{endIndex}
						</strong>{" "}
						{t("ag-grid.locale.of")} {allData.length}
					</div>
				</div>
			</div>
		);

	return {
		pageData,
		paginationElement,
		pageSize,
		currentPageIndex,
	};
};

export default usePagination;
