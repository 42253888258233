import React from "react";
import usePublicCompany from "../hooks/users/usePublicCompany";
import { Building2 } from "lucide-react";
import { cn } from "./utils";

const getInitials = (name) => {
	if (!name) return "";

	const names = name.split(" ");

	if (names.length === 1) {
		return names[0].substring(0, 3) + "";
	}

	const initials = names.reduce((acc, name) => {
		if (name === "AB") return acc;
		return acc + name.charAt(0);
	}, "");

	return initials;
};

const CompanyAvatar = ({
	companyId,
	companyName,
	bgIcon = <Building2 strokeWidth={1} size={32} />,
	size = "md",
}) => {
	const { data: company } = usePublicCompany(companyId);
	const name = company?.name ?? companyName;

	const imageUrl = company?.image?.thumbnail?.filename
		? `/api/companies/public/images/${company.image.thumbnail.filename}-100?companyId=${companyId}`
		: null;

	return (
		<div className={cn("h-16 w-16", { "h-8 w-8": size === "sm" })}>
			{imageUrl ? (
				<div className="bg-white rounded-md overflow-hidden h-full w-full">
					<img src={imageUrl} />
				</div>
			) : (
				<div
					className={cn(
						"relative rounded-md bg-brand-700 text-brand-600/50 w-16 h-16 top-0 left-0",
						{ "h-8 w-8": size === "sm" },
					)}
				>
					<div
						className={cn(
							"absolute h-16 left-0 right-0 bottom-0 flex items-center justify-center",
							{ "h-8": size === "sm" },
						)}
					>
						{bgIcon}
					</div>
					<div
						className={cn(
							"absolute p-2 top-0 left-0 flex h-16 w-16 break-all leading-[12px] text-center font-bold text-brand-100 justify-center items-center",
							{ "h-8 w-8": size === "sm" },
						)}
					>
						{size === "sm" ? name.charAt(0) : getInitials(name)}
					</div>
				</div>
			)}
		</div>
	);
};

export default CompanyAvatar;
