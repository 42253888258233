import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import tippy from "tippy.js";

import { Node } from "@tiptap/core";
import { PluginKey } from "@tiptap/pm/state";
import Suggestion from "@tiptap/suggestion";
import {
	ReactRenderer,
	NodeViewWrapper,
	ReactNodeViewRenderer,
} from "@tiptap/react";

import { cn } from "@/components/utils";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";

const DEFAULT_FIELDS = [
	{ id: "nameFull", description: "The recipient's full name.", type: "string" },
	{
		id: "nameFirst",
		description: "The recipient's first name",
		type: "string",
	},
	{ id: "nameLast", description: "The recipient's last name.", type: "string" },
	{
		id: "emailAddress",
		description: "The recipient's email address.",
		type: "string",
	},
	{
		id: "bankInfo",
		description: "The recipient's bank information.",
		type: "string",
	},
	{
		id: "sharesTotal",
		description: "The recipient's total amount of shares held.",
		type: "number",
	},
	{
		id: "sharesPercentageTotal",
		description: "The recipient's total percentage of shares held.",
		type: "number",
	},
];

const PlaceholderPluginKey = new PluginKey("PlaceholderPluginKey");

const CalculationValueEditor = (props) => {
	const { node, editor, extension } = props;

	const isCalculation = node.attrs.name === "calculation";
	const readOnly = extension.options.readOnly;

	const [isOpen, setIsOpen] = useState(false);
	const [inputValue, setInputValue] = useState(node.attrs.value);

	useEffect(() => {
		editor.setOptions({ editable: !readOnly && !isOpen });
	}, [isOpen]);

	const onSave = (evt) => {
		props.updateAttributes({
			value: evt.target.value,
		});
		setIsOpen(false);
		editor.chain().focus().run();
	};

	return (
		<NodeViewWrapper className="inline">
			{isCalculation ? (
				<Popover open={isOpen}>
					<PopoverTrigger>
						<span
							className="border rounded p-1 bg-purple-100 border-purple-300 text-purple-700 cursor-pointer"
							onClick={() => {
								!readOnly && setIsOpen(!isOpen);
							}}
						>
							{node.attrs.value}
						</span>
					</PopoverTrigger>
					<PopoverContent>
						<Input
							className="font-mono bg-slate-100 text-center"
							autoFocus
							onBlur={onSave}
							value={inputValue}
							onChange={(evt) => setInputValue(evt.target.value)}
							style={{
								width: `max(100px,${inputValue.length * 10}px)`,
							}}
							onKeyDown={(evt) => {
								if (evt.key === "Enter") {
									onSave(evt);
								}
							}}
						/>
					</PopoverContent>
				</Popover>
			) : (
				<span className="border rounded p-1 bg-blue-100 border-blue-300 text-blue-700">
					{node.attrs.name}
				</span>
			)}
		</NodeViewWrapper>
	);
};

const SuggestionList = forwardRef((props, ref) => {
	const [selectedIndex, setSelectedIndex] = useState(0);

	const selectItem = (index) => {
		const item = props.items[index];

		if (item) {
			props.command({ id: item.id });
		}
	};

	const upHandler = () => {
		setSelectedIndex(
			(selectedIndex + props.items.length - 1) % props.items.length,
		);
	};

	const downHandler = () => {
		setSelectedIndex((selectedIndex + 1) % props.items.length);
	};

	const enterHandler = () => {
		selectItem(selectedIndex);
	};

	useEffect(() => setSelectedIndex(0), [props.items]);

	useImperativeHandle(ref, () => ({
		onKeyDown: ({ event }) => {
			if (event.key === "ArrowUp") {
				upHandler();
				return true;
			}

			if (event.key === "ArrowDown") {
				downHandler();
				return true;
			}

			if (event.key === "Enter") {
				enterHandler();
				return true;
			}

			return false;
		},
	}));

	return (
		<div className="bg-white p-2 border border-edge rounded shadow-md">
			{props.items.map((item, idx) => {
				const isActive = idx === selectedIndex;

				return (
					<div
						key={item.id}
						className={cn("p-2 rounded", { "bg-primary": isActive })}
					>
						<div className="font-bold">{item.id}</div>
						<div className="text-sm opacity-75">{item.description}</div>
					</div>
				);
			})}
		</div>
	);
});
SuggestionList.displayName = "SuggestionList";

export const PlaceholderValue = Node.create({
	name: "placeholder",
	group: "inline",
	inline: true,
	atom: true,

	addOptions() {
		return {
			record: {},
		};
	},

	addAttributes() {
		return {
			name: {
				default: "",
			},
			value: {
				default: "",
			},
		};
	},

	parseHTML() {
		return [
			{
				tag: "span[data-variable]",
			},
		];
	},

	renderHTML({ node }) {
		const record = this.options.record;
		const variable = node.attrs.name;
		const isCalculation = variable === "calculation";

		let value, rawValue;

		if (isCalculation) {
			const allowedFields = DEFAULT_FIELDS.filter(
				(f) => f.type === "number",
			).map((f) => f.id);

			const interpolatedCalculation = allowedFields.reduce(
				(acc, field) => acc.replace(new RegExp(field, "g"), record[field] ?? 0),
				node.attrs.value,
			);
			try {
				const evaluationResult = eval(interpolatedCalculation);

				value = evaluationResult.toLocaleString("sv-SE", {
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				});
			} catch (e) {
				value = `Error: ${interpolatedCalculation}`;
			}
			rawValue = value;
		} else {
			rawValue = record?.[variable];
			value = String(rawValue ?? `N/A (${variable})`);
		}

		return [
			"span",
			{
				"data-variable": node.attrs.name,
				class:
					typeof rawValue === "undefined" ? "bg-red-100 text-red-500" : null,
			},

			value,
		];
	},
});

export const Placeholder = Node.create({
	name: "placeholder",
	group: "inline",
	inline: true,
	atom: true,

	addOptions() {
		return {
			fields: DEFAULT_FIELDS,
			suggestion: {
				char: "/",
				items: ({ editor, query }) => {
					const fields = editor.extensionManager.extensions.find(
						(e) => e.name === "placeholder",
					)?.options?.fields;

					return fields.filter((field) =>
						field.id.toLowerCase().includes(query.toLowerCase()),
					);
				},
				render: () => {
					let component;
					let popup;

					return {
						onStart: (props) => {
							component = new ReactRenderer(SuggestionList, {
								props,
								editor: props.editor,
							});

							popup = tippy(props.editor.view.dom, {
								getReferenceClientRect: props.clientRect,
								content: component.element,
								showOnCreate: true,
								interactive: true,
								trigger: "manual",
							});
						},
						onUpdate: (props) => {
							component.updateProps(props);
							popup.setProps({ getReferenceClientRect: props.clientRect });
						},
						onKeyDown: (props) => component.ref?.onKeyDown(props),
						onExit: () => {
							component.destroy();
							popup.destroy();
						},
					};
				},
				pluginKey: PlaceholderPluginKey,
				command: ({ editor, range, props }) => {
					// increase range.to by one when the next node is of type "text"
					// and starts with a space character
					const nodeAfter = editor.view.state.selection.$to.nodeAfter;
					const overrideSpace = nodeAfter?.text?.startsWith(" ");

					if (overrideSpace) {
						range.to += 1;
					}

					editor
						.chain()
						.focus()
						.insertContentAt(range, [
							{
								type: this.name,
								attrs: {
									name: props.id,
								},
							},
							{
								type: "text",
								text: " ",
							},
						])
						.run();

					// get reference to `window` object from editor element, to support cross-frame JS usage
					editor.view.dom.ownerDocument.defaultView
						?.getSelection()
						?.collapseToEnd();
				},
			},
		};
	},

	addAttributes() {
		return {
			name: {
				default: "",
			},
			value: {
				default: "",
			},
		};
	},

	addNodeView() {
		return ReactNodeViewRenderer(CalculationValueEditor);
	},

	parseHTML() {
		return [
			{
				tag: "span[data-variable]",
				getAttrs: (dom) => {
					return {
						name: dom.getAttribute("data-variable"),
					};
				},
			},
		];
	},

	renderHTML({ node }) {
		const isCalculation = node.attrs.name === "calculation";
		return [
			"span",
			{
				"data-variable": node.attrs.name,
				class: cn(
					"border px-1 rounded font-semibold",
					isCalculation
						? "bg-purple-200 border-purple-300 text-purple-700"
						: "bg-blue-200 border-blue-300 text-blue-700",
				),
			},
			isCalculation ? node.attrs.value : node.attrs.name,
		];
	},

	addCommands() {
		return {
			getPlaceholderFields: () => () => {
				return this.options.fields;
			},
			insertPlaceholderCalculation:
				() =>
				({ commands }) => {
					return commands.insertContent({
						type: this.name,
						attrs: { name: "calculation", value: "2+2" },
					});
				},
			insertPlaceholderField:
				(name) =>
				({ commands }) => {
					return commands.insertContent({ type: this.name, attrs: { name } });
				},
		};
	},

	addProseMirrorPlugins() {
		return [
			Suggestion({
				editor: this.editor,
				...this.options.suggestion,
			}),
		];
	},
});
