import React from "react";
import UserCommsList from "./userCommsList";
import { Switch, Route } from "@/interfaces/router";

import SendEmailJobsList from "../sendEmailJobs/sendEmailJobsList";

const UserCommsRoute = ({ match }) => {
	return (
		<div className="flex flex-col p-6 h-[calc(100vh-40px)]">
			<Switch>
				<Route
					path={match.path}
					render={() => (
						<div className="grid grid-cols-2 grid-rows-[max(100%)] items-start gap-6 h-full">
							<UserCommsList />
							<SendEmailJobsList />
						</div>
					)}
				/>
			</Switch>
		</div>
	);
};

export default UserCommsRoute;
