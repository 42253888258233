import React from "react";

import CompanyAvatar from "@/components/companyAvatar";
import UserAvatar from "@/components/userAvatar";
import { Landmark } from "lucide-react";

const Avatar = ({ id, name, type, className, size }) => {
	switch (type) {
		case "capitalInsurance":
			return (
				<CompanyAvatar
					companyId={id}
					companyName={name}
					className={className}
					bgIcon={<Landmark className="mt-[-2px]" strokeWidth={1} size={32} />}
					size={size}
				/>
			);
		case "company":
			return (
				<CompanyAvatar
					companyId={id}
					companyName={name}
					className={className}
					size={size}
				/>
			);
		default:
			return (
				<UserAvatar
					userId={id}
					userName={name}
					className={className}
					size={size}
				/>
			);
	}
};

export default Avatar;
